import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable, OsTableQuery, OsPagination, OsTableOperation } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { ClassifyResource, ImportRes, CustomerShopResource } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import {
  downloadFileByBlob,
  getStatusClass,
  getStatusI18Key,
  handleImportError,
  convertProvinces,
  showWarningConfirm,
  translation,
  messageError,
  getResourceStatusOptions
} from '@/utils';
import { Message } from 'element-ui';
import { ImportTemplateEnum, ResourceStatusEnum } from '@/resource/enum';
import { ImportFile } from '@/views/dialogs/index';
import { ApiResponse } from '@/api/axios';
import { customerShopService } from '@/api';
import AddCustomerShop from './add-customer-shop/add-customer-shop.vue';
@Component({
  name: 'CustomerShop',
  components: { OsTable, OsTableQuery, OsPagination, OsTableOperation, ImportFile, AddCustomerShop }
})
export default class CustomerShop extends Vue {
  public tableOption: OsTableOption<CustomerShopResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerShopResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: 'left'
    },
    {
      prop: 'shopCode',
      label: 'customerShop.code',
      minWidth: '120px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'customCode',
      label: 'customerShop.customerCode',
      minWidth: '120px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'shopName',
      label: 'customerShop.name',
      showOverflowTooltip: true,
      minWidth: '180px',
      fixed: true
    },
    {
      prop: 'address',
      label: 'customerShop.address',
      minWidth: '260px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `
        ${(row as CustomerShopResource).province ?? ''}
        ${(row as CustomerShopResource).city ?? ''}
        ${(row as CustomerShopResource).district ?? ''}
        ${(row as CustomerShopResource).address ?? ''}
        `;
      }
    },
    {
      prop: 'shopOwner',
      label: 'customerShop.shopOwnerName',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'shopTel',
      label: 'customerShop.shopTel',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'receiver',
      label: 'customerShop.receiverInfo',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'receiverTel',
      label: 'customerShop.receiverTel',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'remark',
      label: 'common.remark',
      showOverflowTooltip: true,
      minWidth: '150px'
    },
    {
      prop: 'status',
      label: 'common.status',
      showOverflowTooltip: true,
      minWidth: '100px'
    },

    {
      prop: 'createTime',
      label: 'common.createTime',
      showOverflowTooltip: true,
      minWidth: '180px'
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemOptions: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'keywords',
      label: 'common.keyword',
      option: {
        placeholder: 'common.inputKeyWord'
      }
    },
    {
      type: 'Slot',
      slotName: 'address',
      field: 'provinces',
      label: 'customer.area',
      option: {}
    },
    {
      type: 'Select',
      field: 'status',
      label: 'common.status',
      option: {
        placeholder: 'common.selectStatus'
      },
      optionData: getResourceStatusOptions
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'button.add',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: 'customer:shop:save',
      handleClick: (): void => {
        this.openShopDialog();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'button.import',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'customer:shop:import',
      handleClick: (): void => {
        this.openImportFileDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.delete',
      operationType: 'delete',
      icon: 'el-icon-delete',
      permissionCode: 'customer:shop:batchDelete',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteShop();
      }
    },
    {
      slot: 'start',
      label: 'button.export',
      operationType: 'export',
      icon: 'el-icon-download',
      permissionCode: 'customer:shop:exportData',
      loading: false,
      handleClick: (): void => {
        this.exportData();
      }
    },
    {
      type: 'primary',
      slot: 'end',
      label: 'button.using',
      operationType: 'using',
      icon: 'el-icon-open',
      permissionCode: 'customer:shop:updateStatus',
      handleClick: (): void => {
        this.batchUpdateShopStatus(ResourceStatusEnum.using);
      },
      disabled: true
    },
    {
      type: 'danger',
      slot: 'end',
      plain: true,
      label: 'button.disabled',
      operationType: 'disabled',
      icon: 'el-icon-turn-off',
      permissionCode: 'customer:shop:updateStatus',
      handleClick: (): void => {
        this.batchUpdateShopStatus(ResourceStatusEnum.disabled);
      },
      disabled: true
    }
  ];

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<CustomerShopResource> = {
    fixed: 'right',
    width: '180px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        permissionCode: 'customer:shop:edit',
        handleClick: (item: CustomerShopResource): void => {
          this.openShopDialog(item);
        }
      },
      {
        operationType: 'delete',
        type: 'text',
        label: 'button.delete',
        icon: 'el-icon-delete',
        permissionCode: 'customer:shop:delete',
        handleClick: (item: CustomerShopResource): void => {
          this.deleteShop(item);
        }
      }
    ]
  };
  /**
   * 控制导入的dialog显示隐藏
   */
  public importDialogVisible = false;
  public importTitle = 'dialog.importCustomerShop';
  public shopId: number | null = null;
  public dialogVisible = false;
  public totalData = 0;
  public classifies: Array<ClassifyResource> = [];
  /**
   * 导入模板的名称
   */
  public importTemplate: ImportTemplateEnum = ImportTemplateEnum.customerShop;

  public queryForm: Partial<{
    provinces: Array<string>;
    keywords: string;
    status: ResourceStatusEnum | null;
    companyId: number;
  }> = {
    keywords: '',
    status: null,
    provinces: []
  };

  private selectedRows: Array<CustomerShopResource> = [];

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private companyId = Number(this.$route.query.companyId);

  public activated(): void {
    if (!isNaN(this.companyId) && this.companyId !== Number(this.$route.query.companyId)) {
      this.companyId = Number(this.$route.query.companyId);
      this.reloadData();
    }
  }

  public created(): void {
    this.loadData();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public dialogClosed(): void {
    this.shopId = null;
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.shopTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerShopResource>): void {
    this.selectedRows = selectedData;
  }

  public editSuccess(data: CustomerShopResource): void {
    const findItem = this.tableOption.data.find(x => x.id === data.id);
    Object.assign(findItem, data);
  }

  public importSuccess(path: string): void {
    customerShopService
      .importData(path, this.queryForm.companyId!)
      .then(() => {
        this.reloadData();
        Message.success(translation('dialog.importSuccess'));
        this.importDialogVisible = false;
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public getStatusI18Key(status: ResourceStatusEnum): string {
    return getStatusI18Key(status);
  }

  public getStatusClass(status: ResourceStatusEnum): string {
    return getStatusClass(status);
  }

  private async exportData(): Promise<void> {
    const exportBtn = this.operationOptions.find(x => x.operationType === 'export');
    exportBtn!.loading = true;
    try {
      const exportQuery = {
        ...convertProvinces(this.queryForm.provinces || []),
        ...this.queryForm,
        shopIdList: this.selectedRows.map(x => x.id)
      } as any;
      const blob = await customerShopService.exportData(exportQuery);
      downloadFileByBlob(translation('customerShop.exportFileName'), blob);
    } catch (error) {
      messageError(error);
    } finally {
      exportBtn!.loading = false;
    }
  }

  private openShopDialog(data: CustomerShopResource | null = null): void {
    if (data) {
      this.shopId = data.id;
    }
    this.dialogVisible = true;
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<CustomerShopResource>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType !== 'add' && x.operationType !== 'import' && x.operationType !== 'export') {
        x.disabled = value.length === 0;
      }
    });
  }

  private deleteShop(data: CustomerShopResource): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          await customerShopService.delete(data.id);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private async batchDeleteShop(): Promise<void> {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          const idList: Array<number> = this.selectedRows.map(x => x.id);
          await customerShopService.batchDelete(idList);
          this.reloadData();
          Message.success(translation('operationRes.deleteSuccess'));
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private loadData(): void {
    this.tableOption.loading = true;
    this.queryForm.companyId = this.companyId;
    const query = { ...this.queryForm } as CustomerShopResource;
    if (this.queryForm.provinces?.length === 3) {
      query.province = this.queryForm.provinces[0];
      query.city = this.queryForm.provinces[1];
      query.district = this.queryForm.provinces[2];
    }
    customerShopService
      .getList(query, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private batchUpdateShopStatus(status: ResourceStatusEnum): void {
    const idList = this.selectedRows.map(x => x.id);
    customerShopService
      .batchUpdateShopStatus(idList, status)
      .then(() => {
        this.reloadData();
        Message.success(
          status === ResourceStatusEnum.using
            ? translation('common.usingSuccess')
            : translation('common.disabledSuccess')
        );
      })
      .catch(error => {
        messageError(error);
      });
  }

  private openImportFileDialog(): void {
    this.importDialogVisible = true;
  }
}
