import { CustomerShopResource } from './../../../../../resource/model/customer-management/customer-shop.d';
import { customerShopService, shopTagService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { InstallChargeModeEnum } from '@/resource/enum';
import { ShopTagResource } from '@/resource/model';
import { convertProvinces, messageError, translation } from '@/utils';
import { Form, Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { telOrMobile } from '@/utils/validate';
import { OsAddress } from '@/components';

@Component({})
export default class AddCustomerShop extends mixins(DialogMixin) {
  @Prop({ required: false, type: Number }) public shopId?: number;

  public shopForm: Partial<CustomerShopResource> & { companyId: number; provinces: Array<string> } = {
    companyId: 0,
    shopName: '',
    customCode: '',
    remark: '',
    shopOwner: '',
    shopTel: '',
    provinces: [],
    address: '',
    receiver: '',
    receiverTel: '',
    installChargeMode: InstallChargeModeEnum.shop,
    installFee: 0,
    shopTagList: []
  };

  public resourceFormRules = {
    shopName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerShop.inputName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      },
      {
        validator: (rule: any, value: string, callback: Function): void => {
          customerShopService
            .checkShopName(value, this.shopForm.companyId, this.shopId)
            .then((isRepeat: boolean) => {
              if (isRepeat) {
                callback(translation('common.nameRepeat'));
                return;
              }
              callback();
            })
            .catch(error => {
              callback(error);
            });
        },
        trigger: 'blur'
      }
    ],
    provinces: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('customer.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    receiver: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('customerShop.inputReceiverInfo')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receiverTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('customerShop.inputReceiverTel')));
            return;
          }
          if (!telOrMobile(value)) {
            callback(new Error(translation('common.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    shopTel: [
      {
        required: false,
        validator: (rule: any, value: string | '', callback: Function): void => {
          if (value && !telOrMobile(value)) {
            callback(new Error(translation('common.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    installFee: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value && value !== 0) {
            callback(new Error(translation('customerShop.inputInstallCharge')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public shopTags: Array<ShopTagResource> = [];

  private operationType: 'add' | 'edit' = 'add';

  public async dialogOpen(): Promise<void> {
    this.shopForm.companyId = Number(this.$route.query.companyId);
    this.getShopTags();
    if (!this.shopId) {
      this.operationType = 'add';
      this.title = 'customerShop.addShop';
      return;
    }
    this.operationType = 'edit';
    this.title = 'customerShop.editShop';
    // 处理数据回显
    try {
      const shop = await customerShopService.getById(this.shopId);
      const provinces: Array<string> = [];
      if (shop.province) {
        provinces.push(shop.province);
      }
      if (shop.city) {
        provinces.push(shop.city);
      }

      if (shop.district) {
        provinces.push(shop.district);
      }

      (this.$refs.osAddress as OsAddress)?.reload();
      this.shopForm.provinces = provinces;
      Object.assign(this.shopForm, shop);
    } catch (error) {
      messageError(error);
    }
  }

  public dialogClosed(): void {
    this.$emit('dialog-closed');
    (this.$refs.shopForm as Form).resetFields();
  }

  public onSubmit(): void {
    (this.$refs.shopForm as Form).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        const form = { ...this.shopForm, ...convertProvinces(this.shopForm.provinces) } as any;
        if (this.operationType === 'add') {
          await customerShopService.post(form);
          Message.success(translation('operationRes.addSuccess'));
          this.$emit('add-success');
          this.closeDialog();
          return;
        }
        await customerShopService.put(form);
        Message.success(translation('operationRes.editSuccess'));
        this.$emit('edit-success', form);
        this.closeDialog();
      } catch (error) {
        messageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public tagChecked(tag: ShopTagResource, tagValue: string): void {
    const findIndex = this.shopForm.shopTagList!.findIndex(x => x.tagId === tag.id && x.value === tagValue);
    if (findIndex !== -1) {
      this.shopForm.shopTagList!.splice(findIndex, 1);
      return;
    }
    this.shopForm.shopTagList!.push({
      tagId: tag.id,
      tagName: tag.name,
      value: tagValue,
      shopId: this.shopForm.id
    });
  }

  private get getInstallMode(): string {
    return this.shopForm.installChargeMode === InstallChargeModeEnum.shop
      ? 'customerShop.shopUnit'
      : 'customerShop.areaUnit';
  }

  private getEffect(tagId: number, tagValue: string): string {
    const findIndex = this.shopForm.shopTagList!.findIndex(x => x.tagId === tagId && x.value === tagValue);
    return findIndex === -1 ? 'plain' : 'dark';
  }

  private getShopTags(): void {
    shopTagService
      .getList({ companyId: Number(this.$route.query.companyId) } as any)
      .then(res => {
        this.shopTags = res.data;
      })
      .catch(error => {
        messageError(error);
      });
  }
}
